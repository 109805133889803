import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "./randompictures.css";
import ClientsCount from "./clientsCount";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const randomMillisecondsArr = [1600, 2000, 2400, 2800, 2000];
const getRandomDate = () => {
  const randomNumberIndex = Math.floor(
    Math.random() * randomMillisecondsArr.length
  );
  const randomDate = new Date();
  randomDate.setMilliseconds(
    randomDate.getMilliseconds() + randomMillisecondsArr[randomNumberIndex]
  );
  return randomDate;
};

const Randompictures = () => {
  //const refsArray = useRef([]);
  const map1 = useRef(new Map());
  const map2 = useRef(new Map());

  const addRef = (ref, index) => {
    if (!map1.current.get(index)) {
      map1.current.set(index, { ref, randomDate: getRandomDate() });
    }
  };
  const addRef2 = (ref, index) => {
    if (!map2.current.get(index)) {
      map2.current.set(index, { ref, randomDate: getRandomDate() });
    }
  };

  let imgIndex = -1;
  // Replace these image URLs with your actual image URLs
  const images = [
    {
      number: "1_.jpg",
      title: "الدكتور سعد بدر",
      link: "https://www.facebook.com/baderclinicsps",
      id: "22a837c6-bbe3-4fea-8d42-c57a876f192a",
    },
    {
      number: "2_.jpg",
      title: " الدكتور خلدون العايدي",
      link: "https://www.facebook.com/Drkhaldounalaydi",
      id: "ea8ade3e-6fe0-4a82-a859-c4f3300a4a3c",
    },
    {
      number: "3_.jpg",
      title: "الدكتورة أماني خضر",
      link: "https://www.facebook.com/amani.khader.10",
      id: "9790fe53-dfa3-4f5a-b9ae-c73f0ee482bd",
    },
    {
      number: "4_.jpg",
      title: "الدكتورخالد الشويخ",
      link: "https://www.facebook.com/DrKhalid.DentistClinics",
      id: "0de011d4-5734-4b6b-a48d-8a2537b3f3fe",
    },
    {
      number: "5_.jpg",
      title: "الدكتور حمزة الحسيني",
      link: "https://www.facebook.com/hamzah.adies",
      id: "ca5608fb-e388-4793-98d8-a58815060de0",
    },
    {
      number: "6_.jpg",
      title: "الدكتور شهد الحجازين",
      link: "https://www.facebook.com/Dr.ShahedDentalClinic",
      id: "43a64bbb-5246-4ac9-9730-175405bb5130",
    },
    {
      number: "7_.jpg",
      title: "الدكتور علي الشواهين",
      link: "https://www.facebook.com/ali.alshawaheen",
      id: "42e61be6-9eda-49d7-ae94-21e72b247933",
    },
    {
      number: "8_.jpg",
      title: "الدكتور محمد الكساسبة ",
      link: "https://www.facebook.com/profile.php?id=100077117176035",
      id: "f90df1db-4e61-4234-8181-4488fcc9db5c",
    },
    {
      number: "9_.jpg",
      title: "الدكتور معتز أبوطه ",
      link: "https://www.facebook.com/Dr.MutazAbuTaha",
      id: "d1f68cf8-eb64-4d1c-89ce-09790d9b3781",
    },
    {
      number: "10_.jpg",
      title: "الدكتورة سوزانا حداد",
      link: "https://www.facebook.com/DrSusannaHaddad",
      id: "2944ed42-f926-418e-8383-792d29c12a90",
    },
    {
      number: "11_.jpg",
      title: "الدكتور حسام عرفة ",
      link: "https://www.facebook.com/royal.dental.clinic2021",
      id: "86df3d35-784a-42cd-8e17-31928491cd54",
    },
    {
      number: "12_.jpg",
      title: "الدكتور وحيد صلاح",
      link: "https://www.facebook.com/salah.wahid",
      id: "063c0a68-1090-4021-a326-8c6ffc06d553",
    },
    {
      number: "13_.jpg",
      title: "الدكتورة سناء العسود ",
      link: "https://www.facebook.com/sana.aliswed",
      id: "cb050a3f-07dd-4857-8da6-eb9bdef38593",
    },
    {
      number: "14_.jpg",
      title: "الدكتورة ميسون قنام ",
      link: "https://www.facebook.com/maysoonqannam286",
      id: "0adcb9f1-9e67-4655-a85a-4324d3071d8a",
    },
    {
      number: "15_.jpg",
      title: "الدكتور رأفت البيك",
      link: "https://www.facebook.com/drrafat.albeak",
      id: "17812c7c-94c4-4efa-aaca-843e4aa80056",
    },
    {
      number: "16_.jpg",
      title: "الدكتور سيف نيروخ",
      link: "https://www.facebook.com/nairoukh.saif",
      id: "375c9228-b8a6-42d0-add8-45fa360452c2",
    },
    {
      number: "17_.jpg",
      title: "الدكتور أمين عمرو ",
      link: "https://www.facebook.com/dr.aminamro",
      id: "b1dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "18_.jpg",
      title: "الدكتور معاذ الرطروط ",
      link: "https://www.facebook.com/profile.php?id=100031873946629",
      id: "92355456-3916-4090-a129-7a3b2ba7959a",
    },
    {
      number: "19_.jpg",
      title: "الدكتور ايهاب غياز عنبر",
      link: "https://www.facebook.com/ramDental1",
      id: "e8b0cdbc-8753-45b8-86ff-34c81b27e0f8",
    },
    {
      number: "20_.jpg",
      title: "الدكتور رامي العمري",
      link: "https://www.facebook.com/profile.php?id=100063756296583",
      id: "2b29cdc0-be2e-4b6a-add4-ac84df9dd4fd",
    },
    {
      number: "21_.jpg",
      title: "الدكتور خلدون شاور كاظم شاور",
      link: "https://www.facebook.com/profile.php?id=100069846270084",
      id: "0d471a74-7443-44ac-b366-a5c5ee594407",
    },
    {
      number: "22_.jpg",
      title: "الدكتور عبدالحميد معاوية القضاة",
      link: "https://www.facebook.com/profile.php?id=100057214399439",
      id: "7038ad55-925e-4d16-829b-13bf4c06ec5a",
    },
    {
      number: "23_.jpg",
      title: "الدكتور محمد المناصرة",
      link: "https://www.facebook.com/Manasrahdentalcare",
      id: "bb28853c-d51f-461d-9829-2de49e25d0ce",
    },
    {
      number: "24_.jpg",
      title: "الدكتور لؤي ابو عقيل ",
      link: "https://www.facebook.com/profile.php?id=100008471358795",
      id: "a0b8d2a1-a7e0-4d31-b0ad-de418c1ef4e4",
    },
    {
      number: "25_.jpg",
      title: "الدكتور زيد مازن غوشة",
      link: "https://www.facebook.com/GhoushehDental",
      id: "56b67832-2ff3-44da-bec5-09bb29f22a70",
    },
    {
      number: "26_.jpg",
      title: "الدكتور أحمد الغول",
      link: "https://www.facebook.com/drahmadghoul",
      id: "1792d4f5-63a8-49de-9787-7e9f40cc53b2",
    },
    {
      number: "27_.jpg",
      title: " الدكتور أحمد العطعوط ",
      link: "https://www.facebook.com/DrAhmadAlAtout",
      id: "54ec254c-7dc7-49bb-8ac4-8094f09af59d",
    },
    {
      number: "28_.jpg",
      title: "الدكتور ليث ابو خلف",
      link: "https://www.facebook.com/laithasabukhalaf",
      id: "25c8b46b-b78d-496d-8389-deab095234fe",
    },
    {
      number: "29_.jpg",
      title: "الدكتور محمد القادري",
      link: "https://www.facebook.com/dr.mohammad.alqadry",
      id: "bcdd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "30_.jpg",
      title: " الدكتور أسامة الأشقر ",
      link: "https://www.facebook.com/Doctorosamaashqardentist",
      id: "925ffb92-26f0-461e-a7d2-969bdadd79cb",
    },
    {
      number: "31_.jpg",
      title: "الدكتور رامي العباسي",
      link: "https://www.facebook.com/drramidental",
      id: "b5dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "32_.jpg",
      title: "الدكتورة براء الخطيب",
      link: "https://www.facebook.com/Dr.Baraadentist",
      id: "de70181d-83d9-49c6-9a61-888ccf752774",
    },
    {
      number: "33_.jpg",
      title: "الدكتور بلال الاشهب",
      link: "https://www.facebook.com/SURGIDENTCLINIC",
      id: "74c008e3-3f46-42ac-8bb3-5a5593d0d12d",
    },
    {
      number: "34_.jpg",
      title: " الدكتور عدلي عرندي",
      link: "https://www.facebook.com/dradliarandi",
      id: "a8dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "35_.jpg",
      title: "الدكتور مراد شويكي",
      link: "https://www.facebook.com/villaclinic.co.il",
      id: "f690c6f9-abf4-4ba1-8263-69cc87792e58",
    },
    {
      number: "36_.jpg",
      title: "الدكتور اياد حواشين",
      link: "https://www.facebook.com/DrEyadHawasheen",
      id: "287ba379-ffce-4c4e-a0f3-ddf920302e3b",
    },
    {
      number: "37_.jpg",
      title: "الدكتور حسان شلباية",
      link: "https://www.facebook.com/profile.php?id=100064088732894",
      id: "db4e1ff6-c99c-4ef4-a001-1557f32d639f",
    },
    {
      number: "38_.jpg",
      title: "الدكتور علاء احمد امين",
      link: "https://www.facebook.com/Dr.AlaaAhmadAmeen",
      id: "e4770dc1-8ad1-4ba4-86a6-f7ac81241850",
    },
    {
      number: "39_.jpg",
      title: "الدكتور محمد المصري",
      link: "https://www.facebook.com/profile.php?id=100076347233606",
      id: "6c1d67b2-2aef-45fa-b79a-af8034b0a4c1",
    },
    {
      number: "40_.jpg",
      title: " الدكتورة روان مكاحلة ",
      link: "https://www.facebook.com/profile.php?id=100063634393831",
      id: "8d66a000-ea96-49b0-94ef-2181c2585f47",
    },
    {
      number: "41_.jpg",
      title: "الدكتور هيثم العيايدة",
      link: "https://www.facebook.com/haytham1924",
      id: "f382f14a-adfc-4245-a6a0-4589357fabf8",
    },
    {
      number: "42_.jpg",
      title: "الدكتورة عبير العبادي",
      link: "https://www.facebook.com/DrabeerDentistClinics",
      id: "6d158258-9750-4914-a505-4bc7db959804",
    },
    {
      number: "43_.jpg",
      title: "الدكتور زياد بدوي",
      link: "https://www.facebook.com/zeyad.tareq.9",
      id: "011595c3-52c6-4706-9d92-4ac83272261f",
    },
    {
      number: "44_.jpg",
      title: "الدكتور الوليد الهرش",
      link: "https://www.facebook.com/alwaleed.alhirsh",
      id: "a04eb48b-009b-464a-be9a-439791492abb",
    },
    {
      number: "45_.jpg",
      title: "الدكتورة  آية كرمة",
      link: "https://www.facebook.com/ayakarma",
      id: "368db1d8-be30-4312-b09d-0f85028bdb54",
    },
    {
      number: "46_.jpg",
      title: "الدكتور معتصم دوفش ",
      link: "https://www.facebook.com/duffich",
      id: "5a69f29b-844d-492d-ad3c-4bffe8d61b96",
    },
    {
      number: "47_.jpg",
      title: "الدكتورة أسماء قرش",
      link: "https://www.facebook.com/Drkirresh",
      id: "2b2f0d4c-b539-46bc-a5d2-c6496180dc12",
    },
    {
      number: "48_.jpg",
      title: "الدكتورة رولا الدجاني",
      link: "https://www.facebook.com/ruly166",
      id: "50d68238-71be-4a54-8c37-8d0d306dd860",
    },
    {
      number: "49_.jpg",
      title: "الدكتورة اسراء حجازي",
      link: "https://www.facebook.com/israadental",
      id: "6cea89ee-a4a5-4acd-976f-28c23326da0d",
    },
    {
      number: "50_.jpg",
      title: "الدكتور  سائد ابو علان",
      link: "https://www.facebook.com/saed.y.abuallan",
      id: "5d67077c-c03a-4fa4-8685-89d0dce7d863",
    },
    {
      number: "51_.jpg",
      title: "الدكتور ساري شلالدة",
      link: "https://www.facebook.com/dentaljapanes",
      id: "524c9624-b2fe-4821-9924-89b45f0dad7a",
    },
    {
      number: "52_.jpg",
      title: "الدكتور انس ابو ريان",
      link: "https://www.facebook.com/Dr.Anas_Aburayyan",
      id: "ec853598-d560-4c1f-a3c8-9ee7a17acc39",
    },
    {
      number: "53_.jpg",
      title: " الدكتور احمد جبر",
      link: "https://www.facebook.com/jbr.dental.clinic",
      id: "1c2080c4-c1d7-4474-b8e7-1129a7d8cdc6",
    },
    {
      number: "54_.jpg",
      title: "الدكتورة ديالا ابو خلف",
      link: "https://www.facebook.com/diala.a.khalaf",
      id: "0e94d21d-4413-427b-8477-1dc1d6ef0883",
    },
    {
      number: "55_.jpg",
      title: "الدكتور بلال الزعتري",
      link: "https://www.facebook.com/drbelal.zatari",
      id: "6a92842c-44f7-4807-899e-67695bae9eac",
    },
    {
      number: "56_.jpg",
      title: "الدكتور عبد الرحمن زيدات",
      link: "https://www.facebook.com/amyr.b.amart.3",
      id: "99c534c6-e555-4dd5-8ed2-7c17306a9d41",
    },
    {
      number: "57_.jpg",
      title: "الدكتور علي حميدات",
      link: "https://www.facebook.com/humeidat",
      id: "226a2f5c-80f8-460c-b088-07e90abae142",
    },
    {
      number: "58_.jpg",
      title: "الدكتور زيدان اسعد",
      link: "",
      id: "daf7b0ce-9110-494f-bac1-bb5356cb1c24",
    },
    {
      number: "59_.jpg",
      title: "الدكتور رامي القرم",
      link: "https://www.facebook.com/comfort.care.dental.biddya",
      id: "520aa54b-d741-4011-9b5b-4f10d1b60a27",
    },
    {
      number: "60_.jpg",
      title: " الدكتور سامر المبروك",
      link: "https://www.facebook.com/Dr.Samer.Mabrouk",
      id: "5257041e-7359-441c-baeb-0ecc793c4e88",
    },
    {
      number: "61_.jpg",
      title: "الدكتورة رولا حمدان",
      link: "https://www.facebook.com/dr.rolahamdan",
      id: "8659e933-8134-498c-8811-4b183cd963c5",
    },
    {
      number: "62_.jpg",
      title: " الدكتور عبيدة الجعبري",
      link: "https://www.facebook.com/obayda.e.aljabari",
      id: "4154aeb8-fcf8-4582-9dd1-02361c0c2511",
    },
    {
      number: "63_.jpg",
      title: "أشرف طعمة",
      link: "https://www.facebook.com/drashraf.hmidat1",
      id: "b07aab9e-720e-4ea4-a0aa-4631691b2d60",
    },
    {
      number: "64_.jpg",
      title: "الدكتور زيد النتشة",
      link: "",
      id: "27de476c-4518-e911-80ca-0050560d68b0",
    },
    { number: "65_.jpg", title: "الدكتور محمد يوسف", link: "", id: "" },
    {
      number: "66_.jpg",
      title: "الدكتورة ميعاد المصري",
      link: "https://www.facebook.com/MeaadClinicDentist",
      id: "41055143-5039-48b5-a9f3-ebbf37610fca",
    },
    {
      number: "67_.jpg",
      title: "الدكتور نايف يونس",
      link: "https://www.facebook.com/NAYEFENDO",
      id: "73f65803-34e8-47a7-a5a1-46329ec83e77",
    },
    { number: "68_.jpg", title: " الدكتور ايهاب صلاح الدين", link: "", id: "" },
    {
      number: "69_.jpg",
      title: "الدكتور أنس عليان",
      link: "https://www.facebook.com/dr.anas.elayian",
      id: "70de476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "70_.jpg",
      title: "الدكتور احمد عبدالرحيم",
      link: "",
      id: "51de476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "71_.jpg",
      title: " الدكتور هيثم عيسى",
      link: "https://www.facebook.com/dr.haythamIssa",
      id: "887d7eef-1854-4437-a114-cd1dea2a444c",
    },
    {
      number: "72_.jpg",
      title: " الدكتور هشام عطوان",
      link: "https://www.facebook.com/AtwanOrthodonticClinics",
      id: "67dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "73_.jpg",
      title: "الدكتور بسام الهندي",
      link: "",
      id: "3d66fa3b-70bd-4718-a5f6-cd1a0d6234d0",
    },
    {
      number: "74_.jpg",
      title: "الدكتور رائد الجلاد ",
      link: "https://www.facebook.com/drjalladclinic",
      id: "feba9819-2959-4830-9c82-001a4e6d6e12",
    },
    {
      number: "75_.jpg",
      title: "الدكتورة رنا الشيخ",
      link: "",
      id: "cfc4ec4e-eeb5-4999-bdc6-8430be5accae",
    },
    {
      number: "76_.jpg",
      title: " الدكتور أحمد القضاة",
      link: "",
      id: "9393db75-657f-4e47-8e65-4b367a9e2fff",
    },
    {
      number: "77_.jpg",
      title: "الدكتور يزن سمارة",
      link: "https://www.facebook.com/Dr.YazanSamara",
      id: "451cdfbf-71f7-4bb4-975a-ad02c6dc6ff0",
    },
    {
      number: "78_.jpg",
      title: "الدكتور محمد عمرو ",
      link: "https://www.facebook.com/DrMohammadAmro",
      id: "85dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "79_.jpg",
      title: "الدكتورة هبه عبد الحليم",
      link: "https://www.facebook.com/DR.HEBAABDULHALIM",
      id: "7e3ec117-c960-48c5-9727-fcf13e85b5bc",
    },
    {
      number: "80_.jpg",
      title: " الدكتور رائد ابو هنطش",
      link: "https://www.facebook.com/abuhantashyahoo",
      id: "ad52dfda-3581-4f80-a6c9-8bcd917b134f",
    },
    {
      number: "81_.jpg",
      title: "الدكتور محمد مطر",
      link: "",
      id: "ca2155bf-1f6d-418c-a36b-90aea707bd47",
    },
    {
      number: "82_.jpg",
      title: "الدكتور ساري فوزي",
      link: "",
      id: "3d516b42-7222-46ae-9b11-3f209402c8b1",
    },
    {
      number: "83_.jpg",
      title: "الدكتورة انتماء حماد ",
      link: "https://www.facebook.com/Dr.IntimaaHammad",
      id: "ecca4139-8a67-4f78-87af-7756ff335aa3",
    },
    {
      number: "84_.jpg",
      title: "الدكتور عنان خليف",
      link: "https://www.facebook.com/CrystalTeethPal",
      id: "50dd7c5f-2432-48d7-b314-c19463e98838",
    },
    {
      number: "85_.jpg",
      title: "الدكتور جاك صابات",
      link: "https://www.facebook.com/drjacksabat",
      id: "90dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "86_.jpg",
      title: "الدكتولر أيمن الشوبكي",
      link: "https://www.facebook.com/Dr.AymanAlshoubaki",
      id: "4cde476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "87_.jpg",
      title: "الدكتور خميس جاموس",
      link: "https://www.facebook.com/IRIS.DENTAL.CLINICS",
      id: "f5dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "88_.jpg",
      title: "الدكتور داود الغبن",
      link: "https://www.facebook.com/Alghaben",
      id: "1540224c-9637-4d5a-8742-663a17df84d0",
    },
    {
      number: "89_.jpg",
      title: "الدكتور محمد سمير عودة",
      link: "https://www.facebook.com/MSKDentalCare",
      id: "dfdd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "90_.jpg",
      title: "الدكتور عبيدة فيصل عمرو",
      link: "https://www.facebook.com/profile.php?id=100064920180847",
      id: "b8dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "91_.jpg",
      title: "الدكتور فاروق العمري ",
      link: "https://www.facebook.com/OrangeDentalClinics",
      id: "9429bdab-9f51-4f43-8d6a-c1aab06387ee",
    },
    {
      number: "92_.jpg",
      title: "الدكتورة تهاني ابو التين ",
      link: "https://www.facebook.com/DrTahaniAbualteen",
      id: "f9df7650-d03d-41b5-afb0-9fd18babefc3",
    },
    {
      number: "93_.jpg",
      title: "الدكتور عنان فيصل عمرو",
      link: "https://www.facebook.com/AnanAmro87",
      id: "63dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "94_.jpg",
      title: "الدكتور جريس ديفيد منصور",
      link: "https://www.facebook.com/drjeriesmansour",
      id: "9aebdf23-c4d6-4243-bd23-3a436c87ef0e",
    },
    {
      number: "95_.jpg",
      title: "الدكتور فادي أبراهيم ",
      link: "https://www.facebook.com/fadi.ibrahim.393",
      id: "59de476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "96_.jpg",
      title: "الدكتور سامي الربايعة ",
      link: "https://www.facebook.com/sami.rabaia",
      id: "13de476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "97_.jpg",
      title: " الدكتور شكري جريس",
      link: "https://www.facebook.com/shukri.jiries.7",
      id: "3ede476c-4518-e911-80ca-0050560d68b0",
    },
    { number: "98_.jpg", title: "الدكتور عمار فيصل عمرو ", link: "", id: "" },
    {
      number: "99_.jpg",
      title: "الدكتور احمد قدري دوابشة",
      link: "https://www.facebook.com/ahmaddaboosh.qadridawabsheh",
      id: "448e0dd5-1269-40f0-a724-9ad35348674b",
    },
    {
      number: "100_.jpg",
      title: "الدكتور تامر خروب",
      link: "",
      id: "64dfa365-7c11-444e-bb62-6901777e17a4",
    },
    {
      number: "101_.jpg",
      title: "الدكتور ايمن المعطي",
      link: "",
      id: "13ff7f20-928d-4fe6-a229-29f50c8b5719",
    },
    {
      number: "102_.jpg",
      title: "الدكتورة رائدة تركمان",
      link: "https://www.facebook.com/profile.php?id=100061003825402",
      id: "",
    },
    {
      number: "103_.jpg",
      title: "الدكتور ابراهيم البدن",
      link: "https://www.facebook.com/CRPTON",
      id: "5b828590-5cd6-4532-a97d-2244e288201b",
    },
    {
      number: "104_.jpg",
      title: "الدكتور محمد محيسن",
      link: "https://www.facebook.com/dr.mohammadmohaisen",
      id: "موجود واحد بس من  andorra",
    },
    {
      number: "105_.jpg",
      title: "الدكتور مصعب حماد",
      link: "https://www.facebook.com/perfectdentalcare2012",
      id: "75d20b6b-a62c-4986-b2a2-e2db2885df34",
    },
    {
      number: "106_.jpg",
      title: "الدكتور راسم حمامرة",
      link: "https://www.facebook.com/profile.php?id=100009989731400",
      id: "52938dfd-43b6-4033-a389-024923e156f4",
    },
    {
      number: "107_.jpg",
      title: " الدكتور جهاد عبد الله ",
      link: "https://www.facebook.com/DrjihadAbdallah",
      id: "2eb13e42-45bb-4c97-a1a9-810897b498b9",
    },
    {
      number: "108_.jpg",
      title: "الدكتورة ريم قوار",
      link: "https://www.facebook.com/profile.php?id=100063447446915",
      id: "9338a91d-cb4d-4196-99b1-2342e1d70dd4",
    },
    {
      number: "109_.jpg",
      title: "الدكتور محمد أبو الحاج",
      link: "https://www.facebook.com/miral.dental.center",
      id: "e8dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "110_.jpg",
      title: " الدكتور مايك سعد",
      link: "https://www.facebook.com/profile.php?id=100063011511558",
      id: "0c39f39f-75ac-412e-acb1-e1126a2b1ba2",
    },
    {
      number: "111_.jpg",
      title: "الدكتور مهند منصور",
      link: "https://www.facebook.com/profile.php?id=100054296269576",
      id: "51855a92-7e87-40cb-b84c-cf9d403ef7ed",
    },
    {
      number: "112_.jpg",
      title: "الدكتور عماد الروسان",
      link: "https://www.facebook.com/dremad.clinic",
      id: "120f1a5f-e071-454d-868c-0e71fb2a8466",
    },
    {
      number: "113_.jpg",
      title: "الدكتور باسل مجاهد",
      link: "https://www.facebook.com/Dr.Basil.Mujahed",
      id: "92dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "114_.jpg",
      title: " الدكتور معتز صليبي ",
      link: "https://www.facebook.com/profile.php?id=100063503621390",
      id: "d5fafffd-d77a-4dba-9ab4-094d6c9be59f",
    },
    {
      number: "115_.jpg",
      title: "الدكتور عزات زبون",
      link: "https://www.facebook.com/FamilyDentalCareRamallah",
      id: "0f57393a-73ca-4425-a49a-4ac7b54800ba",
    },
    {
      number: "116_.jpg",
      title: "الدكتورة وسام خرفان",
      link: "https://www.facebook.com/Dr.WisamKhirfanClinic",
      id: "75de476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "117_.jpg",
      title: "الدكتور  محمد البيراوي",
      link: "https://www.facebook.com/ToothFairyPediatricDentalClinic",
      id: "b8a0e346-84c3-430f-80cc-9b06517f504b",
    },
    {
      number: "118_.jpg",
      title: "الدكتورة وسام السعدي",
      link: "https://www.facebook.com/profile.php?id=100063788940016",
      id: "8f26d476-1c2b-4670-a711-5dd6f2e35d10",
    },
    {
      number: "119_.jpg",
      title: "الدكتورة منار هاني",
      link: "https://www.facebook.com/drmanar.hani",
      id: "ce016fc2-4d8d-4342-9292-bb7a7505298d",
    },
    {
      number: "120_.jpg",
      title: "الدكتور محمد زلوم",
      link: "https://www.facebook.com/almasaspecializeddentalclinics",
      id: "e6dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "121_.jpg",
      title: "الدكتور حسين الخطيب",
      link: "خليه لبعدين",
      id: "f0dd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "122_.jpg",
      title: "الدكتور يزن الصمادي",
      link: "https://www.facebook.com/dryazansmadi",
      id: "efdd476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "123_.jpg",
      title: "الدكتور ظلال مراد ",
      link: "https://www.facebook.com/DrThelalMurad",
      id: "36de476c-4518-e911-80ca-0050560d68b0",
    },
    {
      number: "124_.jpg",
      title: "الدكتور محمد ابو الفول",
      link: "https://www.facebook.com/Rosemarydentalclinics",
      id: "edf2462c-b3e8-4058-b716-88644992ef76",
    },
    {
      number: "125_.jpg",
      title: "الدكتور سامر جبريل",
      link: "",
      id: "41e03fe4-9a0e-4622-bc8e-883190a58fcf",
    },
    {
      number: "126_.jpg",
      title: "الدكتور أنس مرمش",
      link: "https://www.facebook.com/alquds.dental.center",
      id: "cf267589-3a27-4d2a-8ff3-826043d53930",
    },
    {
      number: "127_.jpg",
      title: "الدكتور نبيل حمودة",
      link: "",
      id: "a82c106d-951e-43b0-b991-e9f9a8dc4580",
    },
    {
      number: "128_.jpg",
      title: "الدكتورة شذى الهندي",
      link: "https://www.facebook.com/SmileStudioPal",
      id: "64878194-0e88-4f77-a0c4-3ff884687ab6",
    },
    {
      number: "129_.jpg",
      title: "الدكتور غانم ارزيقات",
      link: "https://www.facebook.com/profile.php?id=100091073139217",
      id: "d8107c97-0ef8-407d-967c-e287a5027b4f",
    },
    {
      number: "130_.jpg",
      title: "الدكتور محمد الشلبي",
      link: "",
      id: "e782f12a-769d-4fad-bee1-bc1225b9ae9a",
    },
    {
      number: "131_.jpg",
      title: "الدكتور عامر عوض",
      link: "https://www.facebook.com/lotus.physioth",
      id: "e683193d-f105-46a2-97e6-5e46402902fb",
    },
  ];

  //366,610,244,488

  const imageAreas = [
    [
      {
        height: 370,
      },
      {
        height: 370,
      },
      {
        height: 610,
      },
    ],
    [
      //1342
      {
        height: 250,
      },
      {
        height: 610,
      },
      {
        height: 244,
      },
      {
        height: 244,
      },
    ],
    [
      {
        height: 244,
      },
      {
        height: 244,
      },
      {
        height: 610,
      },
      {
        height: 250,
      },
    ],
    [
      {
        height: 610,
      },
      {
        height: 370,
      },
      {
        height: 370,
      },
    ],
  ];
  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  useEffect(() => {
    // Function to update the randomImages state with new random images every second
    const updateRandomImages = () => {
      if (!map1.current || !map2.current) {
        return;
      }
      map1.current.forEach((value, key) => {
        if (
          value?.ref &&
          map2.current.get(key)?.ref &&
          new Date() >= value.randomDate
        ) {
          const value1 = value;
          const value2 = map2.current.get(key);

          value1.randomDate = getRandomDate();

          if (value1.ref.className == "inactive") {
            value1.ref.src = `clients/${getRandomImage().number}`;
            value1.ref.className = "active";
            value2.ref.className = "inactive";
          } else {
            value2.ref.src = `clients/${getRandomImage().number}`;
            value2.ref.className = "active";
            value1.ref.className = "inactive";
          }
        }
      });
      //setRandomImages(newRandomImages);
    };

    // Set up the interval to update images every second
    const intervalId = setInterval(updateRandomImages, 100);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [map1, map2]);

  const handleMouseEnter = (event) => {
    event.currentTarget.className = "fade-image-container hover";
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.className = "fade-image-container";
  };

  return (
    <Grid container>
      <Grid item xs={2}>
        <h1 style={{ color: "#555555" }}>Crpton</h1>
        2014 - 2023
        <ClientsCount />
        Be part of the Crpton family now!
        <Grid item xs={12} md={12}>
          <a
            href="https://www.facebook.com/crpton"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon
              fontSize="large"
              style={{
                marginTop: "15px ",
                width: "45px",
                height: "45px",
                borderRadius: "7px",
                color: "#3b5998",
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/crpton_dental"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon
              fontSize="large"
              style={{
                width: "45px",
                height: "45px",
                borderRadius: "7px",
                color: "#e4405f",
              }}
            />
          </a>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <h1 style={{ color: "#555555" }}>Our Family</h1>
        <Grid container spacing={1}>
          {imageAreas.map((areas, index) => (
            <Grid key={index} item xs={3}>
              {areas.map((area) => {
                imgIndex++;
                const currentIndex = imgIndex;
                return (
                  <div
                    key={imgIndex}
                    style={{
                      height: area.height + "px",
                      width: "100%",
                    }}
                    className="fade-image-container"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img
                      ref={(ref) => addRef(ref, currentIndex)}
                      src={`/clients/${images[imgIndex].number}`}
                      height={area.height - 8}
                      alt={`Image ${index + 1}`}
                      style={{ objectFit: "cover" }}
                      className="active"
                    />
                    <img
                      ref={(ref) => addRef2(ref, currentIndex)}
                      src={`/clients/${images[imgIndex].number}`}
                      height={area.height - 8}
                      alt={`Image ${index + 1}`}
                      style={{ objectFit: "cover" }}
                      className="inactive"
                    />
                    <div className="fade-image-footer">
                      {images[imgIndex].title}
                    </div>
                    <div
                      className="fade-image-backdrop"
                      style={{
                        height: area.height - 8 + "px",
                      }}
                    ></div>
                  </div>
                );
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Randompictures;
