import React from "react";
import Navbar from "./Navbar";
import { Button, Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    console.log("close");
    setAnchorElNav(null);
  };

  const handleContactUs = (country) => {
    let phoneNumber, message;

    if (country === "Jordan") {
      phoneNumber = "962797443207";
      message =
        "Hello, I am interested in Crpton. Can you help me? مرحبا انا مهتم بكربتون , هل يمكنكم مساعدتي؟";
    } else if (country === "Palestine") {
      phoneNumber = "972566400580";
      message =
        "Hello, I am interested in Crpton. Can you help me? مرحبا انا مهتم بكربتون , هل يمكنكم مساعدتي؟";
    } else {
    }

    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappURL);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={11}>
          <Navbar />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            variant="contained"
            onClick={handleOpenNavMenu}
            style={{
              backgroundColor: "#02C408",
              marginTop: "15px",
              borderRadius: "5px",
              paddingLeft: "25px",
              paddingRight: "25px",
              color: "var(--color-bg) !important",
              textTransform: "none",
              transition: "all 0.3s",

              fontSize: "x-large",
            }}
          >
            WhatsApp
          </Button>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            <MenuItem
              onClick={() => {
                handleContactUs("Palestine");
              }}
            >
              <Typography textAlign="center">Palestine</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleContactUs("Jordan");
              }}
            >
              <Typography textAlign="center">Jordan</Typography>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
