import './AccountDeletionPage.css';

const AccountDeletionPage  = () => {
  return (
	<div className="container">
		<h1>Delete Your Account</h1>
		<p>If you wish to delete your account and all associated data, please follow the steps below:</p>
		<ol>
			<li>Send an email to <a href="mailto:info@crpton.com">info@crpton.com</a> with the subject line "Delete My Account".</li>
			<li>Include your account username and registered email address in the body of the email.</li>
		</ol>
		<h2>Data Deletion Details</h2>
		<p>When you request to delete your account, the following data will be deleted:</p>
		<ul>
			<li>Personal information (name, email address, etc.)</li>
			<li>Account activity logs</li>
			<li>App usage data</li>
		</ul>
		<p>The following data will be retained for a period of 30 days for legal and security reasons:</p>
		<ul>
			<li>Transaction history</li>
			<li>Support interactions</li>
		</ul>
		<p>After the retention period, all data will be permanently deleted from our servers.</p>
	</div>
  );
};

export default AccountDeletionPage ;
