import { Link } from "@mui/material";

const Image = () => {
  return (
    <div>
      <Link href="/">
        <img src="crpton/crpton.png" className="logo"></img>
      </Link>
    </div>
  );
};

export default Image;
