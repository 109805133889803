import { Link, Typography, Grid } from "@mui/material";
import EmailBox from "./Emailbox";
const Paragraph = () => {
  return (
    <>
      <Grid container spacing={0} className="sub-title-paragraph">
        <Grid item xs={12} md={3}>
          <Typography className="title-paragraph"style={{fontSize:"x-large",fontWeight:"bold"  }} > Jordan </Typography>
          <Typography >Arar Street - Al Aqra Building - Amman</Typography>
          <Typography>962797443207 </Typography>
          <Typography>info@crpton.com </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography className="title-paragraph" style={{fontSize:"x-large",fontWeight:"bold"  }} >Palestine </Typography>
          <Typography>Bethlehem, Hebron Al Quds Str. Al Mahd Bldg, </Typography>
          <Typography> 3rd Floor</Typography>
          <Typography>+972566400580 </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography className="title-paragraph" style={{fontSize:"x-large",fontWeight:"bold"  }} >Important Links </Typography>
          <Link href="#" className="footer-link">
            About Us
          </Link>
          <br></br>
          <Link href="#" className="footer-link">
          Clients
          </Link>
          <br></br>
          <Link href="/PrivacyPolicy" className="footer-link">
            Privacy Policy
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <EmailBox />
        </Grid>
      </Grid>
    </>
  );
};

export default Paragraph;
