import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import Image from "./Image";
import { useNavigate } from "react-router-dom";

const pages = [
  { text: "Home", path: "/" },
  { text: "Clients", path: "/Clients" },
  { text: "About Us", path: "/About Us" },
];

const Navbar = () => {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNavMenu = (path) => {
    navigate(path);
    setAnchorElNav(null);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}
    >
      <Toolbar disableGutters>
        <Grid item sx={{ display: { xs: "none", sm: "flex" }, mr: 5 }}>
          <Image />
        </Grid>
        {/* <Typography
          variant="h6"
          noWrap 
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          CRPTON
        </Typography> */}

        <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", sm: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem
                key={page.text}
                onClick={() => {
                  handleNavMenu(page.path);
                }}
              >
                <Typography textAlign="center">{page.text} </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Grid item sx={{ display: { xs: "flex", sm: "none" }, mr: 1 }}>
          <Image />
        </Grid>
        {/* <Typography
          variant="h5"
          noWrap
          component="a"
          href=""
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          CRPTON
        </Typography> */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", sm: "flex", justifyContent: "center" },
          }}
        >
          {pages.map((page) => (
            <Button
              key={page.path}
              onClick={() => {
                handleNavMenu(page.path);
              }}
              sx={{ my: 2, color: "black", display: "block" }}
              style={{
                padding: "0.625rem 1.5rem",
                fontSize: "x-large",
              }}
            >
              {page.text}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </Container>
  );
};

export default Navbar;
