import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { variables } from '../../variables';
import { BottomNavigation, BottomNavigationAction, Button, Grid } from '@mui/material';


export default function Services() {
  const [value, setValue] = React.useState('');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const [departments, setDepartments] = useState([]);
  function refreshList() {
    fetch(variables.API_URL + 'department')
      .then(response => response.json())
      .then(data => {
        setDepartments(data);
      });
  }
  
  
  useEffect(() => {
    refreshList();
  }, []);










  return (

   






 

<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
 {
  departments.map((x)=>{
    return  <Grid item xs={6} sm={4} md={3} lg={1.34}>

   <Button 
   startIcon={<img src={'/icons/department/' + x.icons }
    label={x.departmentname}
    value={x.departmentname}
     />}>
   
   </Button>
  
  </Grid>
  })
 }
 
</Grid>



     
 

      
    
  );
}





