import { Grid } from "@mui/material";
import { useParams } from 'react-router-dom';
import Image from 'mui-image'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { useEffect } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShareIcon from '@mui/icons-material/Share';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css';
import 'swiper/less';
import 'swiper/less/navigation';
import 'swiper/less/pagination';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Box } from '@mui/material';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


const Body5 = () => {

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    const [cards, setCards] = useState([]);

    useEffect(() => {
        // هذه بيانات مثالية، استبدلها ببيانات الـ API الخاصة بك
        const mockData = [
            { id: 1, title: 'Slide 1', description: 'Description for Slide 1' },
            { id: 2, title: 'Slide 2', description: 'Description for Slide 2' },
            { id: 3, title: 'Slide 3', description: 'Description for Slide 3' },
            { id: 1, title: 'Slide 1', description: 'Description for Slide 1' },
            { id: 2, title: 'Slide 2', description: 'Description for Slide 2' },
            { id: 3, title: 'Slide 3', description: 'Description for Slide 3' },
            { id: 1, title: 'Slide 1', description: 'Description for Slide 1' },
            { id: 2, title: 'Slide 2', description: 'Description for Slide 2' },
            { id: 3, title: 'Slide 3', description: 'Description for Slide 3' },
            // أضف المزيد من البيانات حسب الحاجة
        ];

        // تعيين البيانات الوهمية إلى الحالة
        setCards(mockData);
    }, []);

    return (

        <Grid
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            mt={10}
        >

            <Grid container xs={10} alignItems="center" justifyContent="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>


                <Grid item xs={6}>
                    <Card sx={{ width: '100%', height: '100%', padding: '15px', boxShadow: 'none', border: 'none' }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                sx={{ width: 80, height: 80, }}
                                image="crpton/management-svgrepo-com 1.png"
                                alt="dataflow"
                            />
                            <CardContent sx={{ padding: 0, marginTop: '20px' }}>
                                <Typography gutterBottom variant="h5" component="div" sx={{ marginTop: '30px', fontFamily: 'Cursive', fontSize: 34, width: '50%' }}>
                                    Streamlined Patient Management
                                </Typography >
                                <Typography variant="body2" color="text.secondary" sx={{ marginTop: '30px', width: '85%', fontSize: 24 }}>
                                    Crpton's Desktop Application empowers doctors with a user-friendly patient management system. Easily
                                    schedule appointments, access medical records, and streamline patient information for efficient and personalized care.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions sx={{}}>
                            <Button size="large" sx={{ backgroundColor: 'black', color: 'white', marginTop: '30px', fontSize: 14, padding: '10px 25px' }}>
                                Learn More
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>



                <Grid item xs={6}>
                    <Image src="crpton/Rectangle 28.png" style={{ width: '100%', height: '500px' }} />
                </Grid>



                <Grid container xs={12} rowSpacing={1} mt={5} bgcolor="#45585b" color="primary.contrastText" style={{ borderRadius: '10px', height: '100px' }} >



                    <Grid item container xs={4} textAlign="center">

                        <Grid item xs={4}>
                            <Typography gutterBottom variant="h5" component="div">+300</Typography>
                            <Typography gutterBottom variant="h5" component="div">Doctors </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography gutterBottom variant="h5" component="div">+300</Typography>
                            <Typography gutterBottom variant="h5" component="div">Doctors</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography gutterBottom variant="h5" component="div">+300</Typography>
                            <Typography gutterBottom variant="h5" component="div"> Doctors </Typography>
                        </Grid>

                    </Grid>


                    <Grid item xs={4} textAlign="center">
                        <Typography gutterBottom variant="h5" component="div">+300</Typography>
                        <Typography gutterBottom variant="h5" component="div">Doctors </Typography>
                    </Grid>

                    <Grid item container xs={4} textAlign="center">

                        <Grid item xs={4}>
                            <Typography gutterBottom variant="h5" component="div">+300</Typography>
                            <Typography gutterBottom variant="h5" component="div">Doctors </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography gutterBottom variant="h5" component="div">+300</Typography>
                            <Typography gutterBottom variant="h5" component="div">Doctors</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography gutterBottom variant="h5" component="div">+300</Typography>
                            <Typography gutterBottom variant="h5" component="div"> Doctors </Typography>
                        </Grid>

                    </Grid>



                </Grid>


                <Grid
                    mt={10}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >

                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar]}
                        spaceBetween={50}
                        slidesPerView={2}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                    >
                        {cards.map(card => (


                            <SwiperSlide key={card.id} style={{justifyContent: 'center',alignItems: 'center',display: 'flex'}}>
                                <div style={{
                                     padding: '20px',
                                     display: 'flex',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     height: '500PX', 
                                     width: '80%',
                                     background: 'linear-gradient(135deg, rgba(44, 171, 198, 1) 0%, rgba(223, 210, 159, 1) 100%)', 
                                     borderRadius: '15px', 

                                }}>

                                    <Card sx={{
                                        maxWidth: "90%", 
                                        height:"90%",
                                        borderRadius: '5px',
                                        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                                        transition: '0.3s',
                                        '&:hover': {
                                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                        },
                                    }}>
                                        <CardHeader
                                            sx={{ padding: '10%'}}
                                            avatar={
                                                <Avatar sx={{ bgcolor: 'secondary.main' }} aria-label="recipe">
                                                    <img src="path-to-avatar.jpg" alt="Dr Aseel Salh" />
                                                </Avatar>
                                            }
                                            title="Dr Aseel Salh"
                                            titleTypographyProps={{ variant: 'subtitle1' }}
                                            subheader="Dentist"
                                            subheaderTypographyProps={{ variant: 'caption' }}
                                        />
                                        <CardContent sx={{ paddingRight: '5%',paddingLeft:'10%' }}>
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                Its a great application to organize the clinic appointment, it's simple and easy to use. Really its make a difference in clinic. Thank s CRPTON
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                <FacebookIcon color="primary" sx={{ mr: 1 }} />
                                                <Rating name="read-only" value={5} readOnly size="small" />
                                                <Typography variant="body2" color="text.secondary" component="span" sx={{ ml: 1 }}>
                                                    5.00
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </div>
                            </SwiperSlide>


                        ))}
                    </Swiper>

                </Grid>








                <Grid container xs={12} m={2} sx={{ height: 240 }} justifyContent="center" alignItems="center">
                    <Grid item xs={2.2} sx={{ height: "100%" }} >
                        <Card sx={{ height: "100%", width: "90%", borderRadius: "15px" }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ width: "60px", height: "60px" }} aria-label="recipe">
                                        <img src="crpton/google-icon-2048x2048-czn3g8x8 1.png" alt="R" />
                                    </Avatar>
                                }
                            />
                            <CardContent>
                                <Typography variant="h5" color="text.secondary"> Google </Typography>
                                <Typography variant="body2" color="text.secondary"> Crpton's Desktop Application empowers doctors with</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={2.7} sx={{ height: "100%" }} justifyContent="center" alignItems="center"  >

                        <Grid item xs={11} sx={{ height: "45%" }}>
                            <Card sx={{ height: "100%", borderRadius: "15px" }} >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ width: "45px", height: "45px" }} aria-label="recipe">
                                            <img src="crpton/X.png" alt="R" />
                                        </Avatar>
                                    }
                                    title="X"
                                    subheader="Crpton's Desktop Application empowers doctors with"
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ height: "10%" }}> </Grid>


                        <Grid item xs={11} sx={{ height: "45%" }}>
                            <Card sx={{ height: "100%", borderRadius: "15px" }} >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ width: "45px", height: "45px" }} aria-label="recipe">
                                            <img src="crpton/X.png" alt="R" />
                                        </Avatar>
                                    }
                                    title="X"
                                    subheader="Crpton's Desktop Application empowers doctors with"
                                />
                            </Card>
                        </Grid>

                    </Grid>
                    <Grid item xs={2.2} sx={{ height: "100%" }} >
                        <Card sx={{ height: "100%", width: "90%", borderRadius: "15px" }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ width: "60px", height: "60px" }} aria-label="recipe">
                                        <img src="crpton/google-icon-2048x2048-czn3g8x8 1.png" alt="R" />
                                    </Avatar>
                                }
                            />
                            <CardContent>
                                <Typography variant="h5" color="text.secondary"> Google </Typography>
                                <Typography variant="body2" color="text.secondary"> Crpton's Desktop Application empowers doctors with</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={2.7} sx={{ height: "100%" }} justifyContent="center" alignItems="center"  >

                        <Grid item xs={11} sx={{ height: "45%" }}>
                            <Card sx={{ height: "100%", borderRadius: "15px" }} >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ width: "45px", height: "45px" }} aria-label="recipe">
                                            <img src="crpton/X.png" alt="R" />
                                        </Avatar>
                                    }
                                    title="X"
                                    subheader="Crpton's Desktop Application empowers doctors with"
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ height: "10%" }}> </Grid>


                        <Grid item xs={11} sx={{ height: "45%" }}>
                            <Card sx={{ height: "100%", borderRadius: "15px" }} >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ width: "45px", height: "45px" }} aria-label="recipe">
                                            <img src="crpton/X.png" alt="R" />
                                        </Avatar>
                                    }
                                    title="X"
                                    subheader="Crpton's Desktop Application empowers doctors with"
                                />
                            </Card>
                        </Grid>

                    </Grid>
                    <Grid item xs={2.2} sx={{ height: "100%" }} >
                        <Card sx={{ height: "100%", width: "90%", borderRadius: "15px" }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ width: "60px", height: "60px" }} aria-label="recipe">
                                        <img src="crpton/google-icon-2048x2048-czn3g8x8 1.png" alt="R" />
                                    </Avatar>
                                }
                            />
                            <CardContent>
                                <Typography variant="h5" color="text.secondary"> Google </Typography>
                                <Typography variant="body2" color="text.secondary"> Crpton's Desktop Application empowers doctors with</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>






            </Grid>
        </Grid>
    );
};
export default Body5;
