import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Body from "./component/Body/Body";
import Footer from "./component/Footer/Footer";
import Header from "./component/Header/Header";
import Contact from "./component/contact/contact";
import RandomPictureDisplay from "./component/Body/RandomPictureDisplay"; // Import the RandomPictureDisplay component
import Randompictures from "./component/Body/randompictures";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import AccountDeletionPage from "./pages/AccountDeletionPage";
import Services from "./component/Services/Services";
import DepartmentBody from "./component/Departments/DepartmentBody";
import Departments from "./component/Departments/Departments";
import { Button } from "@mui/material";
import SvgIcon from '@mui/material/SvgIcon';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'; // Import the download icon
import { Home } from "@mui/icons-material";
import HomeDepartments from "./component/Body/HomeDepartments";

function App() {
  return (
    <div className="App">
      <Router>


        <div className="header-body">
        <div style={{ paddingRight: "10%" }}>
            <Header />
          </div>

          <div className="Body">
            <Routes>
              <Route exact path="/" element={<Body />} />
              <Route exact path="/Home" element={<Home />} />
              <Route exact path="/Services" element={<Services />} />
              <Route exact path="/About Us" element={<Contact />} />
              <Route exact path="/PrivacyPolicy" element={<PrivacyPolicyPage />} />
              <Route exact path="/AccountDeletionPage" element={<AccountDeletionPage />} />
            </Routes>
          </div>
        </div>

        <Routes>
          <Route exact path="/DepartmentBody" element={<DepartmentBody />} />
          <Route exact path="/Clients" element={<Randompictures />} />
        </Routes>



        <div className="footer">
          <Footer />
        </div>


      </Router>
    </div>



  );
}

export default App;
