import React from 'react';
import { Grid, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Body2 from "./Body/Body2";
import Body3 from "./Body/Body3";
import Body4 from "./Body/Body4";
import Body5 from "./Body/Body5";

import Departments from "../Departments/Departments";
import Search from "./Search";





const Body = () => {
  const { departmentId } = useParams();
  console.log(departmentId);







  return (
    <Grid container spacing={2}>
    <Grid item xs={12} md={5}>
      <Typography className="top-paragrph">Manage Your </Typography>
      <Typography className="top-paragrph"> Clinic Today</Typography>
      <Typography className="top-paragrph">
        with <span id="highlight">Crpton</span>
      </Typography>
      <div className="second-paragrph">
      <Typography >
      Crpton Clinic Management Software: Simplifying clinic management for doctors of all sizes, with ease and efficiency.
      </Typography>

      </div>

      <Grid item xs={12} md={12} sx={{marginBottom: '50px'}}>
      <a
          href="https://www.facebook.com/crpton" 
          target="_blank"
          rel="noopener noreferrer"
        >
       <FacebookIcon   fontSize="large" style={{ marginTop:"15px ",width: '45px', height: '45px', borderRadius: '7px',color: '#3b5998' }} />
        </a>
        <a
          href="https://www.instagram.com/crpton_dental" 
          target="_blank"
          rel="noopener noreferrer"
        >
      <InstagramIcon  fontSize="large" style={{ width: '45px', height: '45px', borderRadius: '7px' ,color: '#e4405f'}}/>
      </a>
      </Grid>
    </Grid>
    <Grid item xs={12} md={6} sx={{ textAlign:'center'}}>
      <img src="crpton/crpton_hero.jpg" className="contant-image"></img>
    </Grid>
  </Grid>
  );
};

export default Body;
