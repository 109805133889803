import Paragraph from "./Paragraph";

import { Grid } from "@mui/material";

const Footer = () => {
  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Paragraph />
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
