
import { Grid, Typography } from "@mui/material";

import AllDepartments from "../Departments/AllDepartments";
import { wait } from "@testing-library/user-event/dist/utils";
import Departments from "../Departments/Departments";
import { useParams } from "react-router-dom";
function DepartmentBody() {
  
    console.log();
    return (
  

        //<Grid container spacing={0}>
        <Grid container rowSpacing={1} justifyContent="flex-end" alignItems="center" columnSpacing={{ xs: 1, sm: 2, md: 3 ,lg:2}}>             
            <AllDepartments />

        </Grid>
        //  </Grid>









    );
};

export default DepartmentBody;
