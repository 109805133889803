import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";

import Link from "@mui/material/Link";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function OutlinedCard({ title, address, email, phone }) {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined" className="ee" sx={{marginBottom: '35px', paddingBottom:'50px'}}>
        <CardContent>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {address}
          </Typography>
          <Typography variant="body2"> {email}</Typography>
          <Typography variant="body2"> Phone: +{phone}</Typography>

          <Link
            href={`https://api.whatsapp.com/send?phone=${phone}`}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            contact
          </Link>
        </CardContent>
      </Card>
    </Box>
  );
}
