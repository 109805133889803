import OutlinedCard from "./addresscard";
import { Grid } from "@mui/material";

import Contactform from "./contactform";

const contact = () => {
  return (
    <Grid container spacing={0}>
      <Grid container xs={12} md={6}>
        <Grid item xs={12} md={6}>
          <OutlinedCard
            title="Jordan"
            address="Arar Street - Al Aqra Building - Amman"
            email="Email: info@crpton.com"
            phone="962797443207"
          />
          <OutlinedCard
            title="USA"
            address="99 Wall Street #1473 New York, NY 10005"
            email="Email: info@crpton.com"
            phone="6469806015 "
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OutlinedCard
            title="Palestine"
            address="Bethlehem, Hebron Al Quds Str. Al Mahd Bldg, 3rd Floor"
            email="Email: info@crpton.com"
            phone="972566400580"
            
          />

          <OutlinedCard
            title="Malaysia"
            address="DaGate Resources Sdn. Bhd , Level 14/15 D'Pulze, Lingkaran Cyber Point Timur, Cyber 12, 63000 Cyberjaya, Selangor"
            email="Email: info@crpton.com"
            phone="60 17 690 0570"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Contactform />
      </Grid>
    </Grid>
  );
};

export default contact;
