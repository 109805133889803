import React, { useEffect, useRef, useState } from "react";

const ClientsCount = () => {
  const clientsNumberRef = useRef();

  var speed = 1;
  var endNbr = 0;
  /* Call this function with a string containing the ID name to
   * the element containing the number you want to do a count animation on.*/
  function incEltNbr() {
    endNbr = Number(1000);
    incNbrRec(0, endNbr);
  }

  /*A recursive function to increase the number.*/
  function incNbrRec(i, endNbr) {
    if (i <= endNbr) {
      try{
        clientsNumberRef.current.innerHTML = i;
      }
      catch{}
      setTimeout(function () {
        //Delay a bit before calling the function again.
        incNbrRec(i + 4, endNbr);
      }, speed);
    }
  }

  useEffect(() => {
    if (clientsNumberRef.current) {
      incEltNbr();
    }

    // Clean up the interval when the component unmounts
  }, [clientsNumberRef]);

  return (
    <h2 style={{ display: "flex", flexWrap: "wrap" }}>
      <span ref={clientsNumberRef}>1000</span>+
    </h2>
  );
};
export default ClientsCount;
