import { TextField, Button ,Typography,Grid} from "@mui/material";
const EmailBox = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography className="title-paragraph " style={{fontSize:"x-large",fontWeight:"bold"  }} >
Subsribe Now
      </Typography>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={7}>
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        // Add any additional props or styling as needed
      />
      </Grid>
      <Grid item xs={12} sm={6}>
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
      </Grid>
      </Grid>
    </form>
  );
};
export default EmailBox;
